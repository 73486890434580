<template>
<div class="iq-card wallet-settings">
  <div class="iq-card-body position-relative w-100 d-flex gap_2" :class="{'flex-column': editMode}">
    <h4 class="wallet-settings-box-title">نسيان رمز ال PIN</h4>
    <div v-if="!editMode" class="wallet-settungs-box">
      <h5 class="wallet-settungs-box-info wallet-settungs-box-info-pin">******</h5>
    </div>
    <div v-else  class="wallet-settungs-box">
        <p class="text-center font-size-22  text-black font-weight-bold">لنسيان الرقم السرى للمحفظة</p>
      <div @click="toggleCorrectReset" v-if="!correctReset" class="mb-2 mt-3 d-flex justify-content-center">
        <b-button class=" text-white w-25 py-2" variant="warning" type="button">
          ارسال الرمز
        </b-button>
      </div>
      <ValidationObserver v-else v-slot="{ handleSubmit }">
        <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(addNewPassword)">
          <b-row>
            <b-col lg="12">
              <input-form
                  class="mb-3"
                  v-model="pinPassword.reset_pin_code_token"
                  placeholder="######"
                  :validate="'required'"
                  :name="$t('auth.codeOtb')"
                  :label="$t('endUser.otbCode')"
              />
            </b-col>
            <b-col lg="6">
              <input-form
                  class="mb-3"
                  v-model="pinPassword.PIN_code"
                  :validate="'required'"
                  :placeholder="$t('auth.newPassword')"
                  name="newPassword"
                  :label="$t('auth.newPassword')"
                  type="password"
              />
            </b-col>
            <b-col lg="6">
              <input-form
                  class="mb-3"
                  v-model="pinPassword.confirm_PIN_code"
                  :validate="'required|confirmed:newPassword'"
                  :placeholder="$t('auth.passwordChecked')"
                  :name="$t('auth.passwordChecked')"
                  :label="$t('auth.passwordChecked')"
                  type="password"
              />
            </b-col>
            <b-col lg="12">
              <div class="d-flex justify-content-center mt-4" >
                <b-button class="text-white w-20 py-2" variant="primary" type="submit" v-if="!submitLoading">
                  <span>{{ $t('auth.save') }}</span>
                </b-button>
                <b-button v-else class=" text-white w-20 py-2" variant="primary" disabled>
                  <spinner-loading text="saving"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </div>
    <b-button class="bg-transparent border-0 rounded-0 p-0 m-0 position-absolute border--bottom wallet-edit-btn" @click="toggleEditMode">تغير</b-button>
  </div>
</div>
</template>

<script>
import websiteServices from '../services/userProfile'
import { core } from '@/config/pluginInit'
// import authService from '@/modules/auth/services/auth'
export default {
  data () {
    return {
      editMode: false,
      correctReset: false,
      submitLoading: false,
      pinPassword: {
        reset_pin_code_token: '',
        PIN_code: '',
        confirm_PIN_code: ''
      }
    }
  },
  methods: {
    toggleEditMode () {
      this.editMode = !this.editMode
    },
    toggleCorrectReset () {
      websiteServices.forgetPinCode().then(res => {
        core.showSnackbar('success', res.data.message)
      }).finally(
        this.correctReset = !this.correctReset
      )
    },
    // changePin () {
    //   websiteServices.editPinCode(this.pin).then(res => {
    //     core.showSnackbar('success', res.data.message)
    //   })
    // }
    addNewPassword () {
      this.submitLoading = true
      websiteServices.createPinCode(this.pinPassword).then(res => {
        core.showSnackbar('success', res.data.message)
        // this.$router.push({ path: 'user-profile/wallet' })
        this.$router.go(-1)
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>

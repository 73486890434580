<template>
<div class="iq-card">
  <div class="iq-card-body position-relative w-100 d-flex flex-wrap gap_5">
    <h4 class="wallet-settings-box-title">الحساب البنكى</h4>
    <div v-if="!editMode" class="wallet-settungs-box">
      <div class="mb-4">
        <h6 class="wallet-settungs-box-info-title">اسم صاحب الحساب</h6>
        <h5 class="wallet-settungs-box-info">{{account.account_name ? account.account_name : 'لا يوجد' }}</h5>
      </div>
      <div class="mb-4">
        <h6 class="wallet-settungs-box-info-title">اسم البنك</h6>
        <h5 class="wallet-settungs-box-info">{{ account.bank_name ? account.bank_name : 'لا يوجد' }}</h5>
      </div>
      <div class="">
        <h6 class="wallet-settungs-box-info-title">رقم IBAN</h6>
        <h5 class="wallet-settungs-box-info iban text-right" dir="ltr">
          {{ account.IBAN ? hashIban(account.IBAN) : 'لا يوجد'}}
        </h5>
      </div>
    </div>
    <div v-else  class="wallet-settungs-box">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(editWalletInfo)">
          <div class="position-relative mb-4">
            <input-form
              name="account-name"
              label="اسم صاحب الحساب"
              placeholder="اسم صاحب الحساب"
              v-model="accountNew.account_name"
            />
          </div>
          <div class="position-relative mb-4">
            <input-form
              name="bank-name"
              label="اسم البنك"
              placeholder="اسم البنك"
              v-model="accountNew.bank_name"
            />
          </div>
          <div class="position-relative mb-1">
            <input-form
              name="iban"
              label="رمز IBAN"
              placeholder="رمز IBAN"
              v-model="accountNew.IBAN"
            />
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="save-setting-btn" type="sumbit" v-if="!loadingEdit">حفظ</b-button>
            <b-button variant="primary" class="save-setting-btn" type="button" v-else>
              <spinner-loading text="يتم الحفظ"/>
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
    <b-button class="bg-transparent border-0 rounded-0 p-0 m-0 position-absolute border--bottom wallet-edit-btn" @click="toggleEditMode">تعديل</b-button>
  </div>
</div>
</template>

<script>
import websiteServices from '../services/userProfile'
import { core } from '@/config/pluginInit'
export default {
  props: {
    bankInfo: {
      type: Object
    }
  },
  data () {
    return {
      editMode: false,
      account: {
        account_name: '',
        bank_name: '',
        IBAN: ''
      },
      loadingEdit: false,
      accountNew: { ...this.account }
    }
  },
  watch: {
    bankInfo (val) {
      if (val) {
        this.account = val
        this.accountNew = { ...val }
      }
    }
  },
  methods: {
    editWalletInfo () {
      this.loadingEdit = true
      websiteServices.editWalletInfo({ bank_info: this.accountNew }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.account = this.accountNew
        this.editMode = false
      }).finally(() => {
        this.loadingEdit = false
      })
    },
    toggleEditMode () {
      this.editMode = !this.editMode
    },
    hashIban (iban) {
      const len = iban.length - 3 // 10
      let hashing = ''
      for (let i = 0; i < len; i++) {
        hashing += '*'
      }
      return iban.substring(0, 3) + hashing
    }
  }
}
</script>

<template>
<div class="iq-card wallet-settings">
  <div class="iq-card-body position-relative w-100 d-flex gap_2" :class="{'flex-column': editMode}">
    <h4 class="wallet-settings-box-title">رمز ال PIN</h4>
    <div v-if="!editMode" class="wallet-settungs-box">
      <h5 class="wallet-settungs-box-info wallet-settungs-box-info-pin">******</h5>
    </div>
    <div v-else  class="wallet-settungs-box">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(changePin)">
          <b-row>
        <b-col md="auto">
          <div class="position-relative mb-4">
            <input-form
              name="pin"
              label="رمز ال PIN الحالى"
              validate="required|digits:5|numeric"
              placeholder="الرمز الحالي"
              v-model="pin.current_PIN"
              class="d-flex align-items-center flex-wrap gap_2 pin-input"
            />
          </div>
          <div class="position-relative mb-4">
            <input-form
              name="pin"
              validate="required|digits:5|numeric"
              label="ادخل الرمز الجديد"
              placeholder="الرمز الجديد"
              v-model="pin.new_PIN"
              class="d-flex align-items-center flex-wrap gap_2 pin-input"
            />
          </div>
          <div class="position-relative mb-4">
            <input-form
              name="pin"
              validate="required"
              label="تأكيد الرمز الجديد"
              placeholder="الرمز الجديد"
              v-model="pin.confirm_pin"
              class="d-flex align-items-center flex-wrap gap_2 pin-input"
            />
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="save-setting-btn" type="submit">حفظ</b-button>
          </div>
        </b-col>
      </b-row>
        </b-form>
      </ValidationObserver>
    </div>
    <b-button class="bg-transparent border-0 rounded-0 p-0 m-0 position-absolute border--bottom wallet-edit-btn" @click="toggleEditMode">تعديل</b-button>
  </div>
</div>
</template>

<script>
import websiteServices from '../services/userProfile'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      editMode: false,
      pin: {
        current_PIN: '',
        new_PIN: '',
        confirm_pin: ''
      }
    }
  },
  methods: {
    toggleEditMode () {
      this.editMode = !this.editMode
    },
    changePin () {
      websiteServices.editPinCode(this.pin).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.go(-1)
      })
    }
  }
}
</script>

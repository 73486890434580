<template>
<div class="iq-card wallet-settings">
  <div class="iq-card-body position-relative w-100 d-flex gap_5">
    <h4 class="wallet-settings-box-title">الاشعارات</h4>
    <div class="wallet-settungs-box">
      <b-form-checkbox-group
        v-model="selected"
        :options="options"
        class="wallet-notifications d-flex gap_1 flex-wrap"
        value-field="item"
        text-field="name"
        @change="editWalletInfo"
      ></b-form-checkbox-group>
    </div>
  </div>
</div>
</template>

<script>
import websiteServices from '../services/userProfile'
import { core } from '@/config/pluginInit'

export default {
  props: ['notifications_setting'],
  data () {
    return {
      selected: [
        /* { website_notifications: false },
        { sms_notifications: false },
        { email_notifications: false } */
      ],
      options: [
        { item: { website_notifications: true }, name: 'اشعارات الموقع' },
        { item: { sms_notifications: true }, name: 'رسائل نصية على الهاتف' },
        { item: { email_notifications: true }, name: 'البريد الالكترونى' }
      ]
    }
  },
  methods: {
    editWalletInfo () {
      var allData = {}
      this.selected.forEach(data => {
        allData[Object.keys(data)] = data[Object.keys(data)]
      })
      websiteServices.editWalletInfo({ notifications_setting: allData }).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  watch: {
    notifications_setting (val) {
      if (val) {
        for (const i in val) {
          this.selected.push({ [i]: val[i] })
        }
      } else {
        this.selected = [
          { website_notifications: false },
          { sms_notifications: false },
          { email_notifications: false }
        ]
      }
    }
  }
}
</script>

<template>
  <div class="wallet-settings">
    <div class="wallet-settings-title">
      <h3 class="text">اعدادات المحفظة</h3>
    </div>
    <!-- bank account  -->
    <setting-bank-account :bankInfo='bankInfo' />
    <!-- notificatioin  -->
    <setting-notification :notifications_setting="notifications_setting" />
    <!-- OTP  -->
<!--    <otp-setting  :activateOtb="activate_otb" />-->
    <!-- pin code -->
    <setting-pin-code />
    <forget-pin-code />
  </div>
</template>

<script>
import settingBankAccount from '../../components/settingBankAccount'
import settingPinCode from '../../components/settingPinCode'
import forgetPinCode from '../../components/forgetPinCode'
import settingNotification from '../../components/settingNotification.vue'
// import otpSetting from '../../components/otpSetting.vue'
import websiteServices from '../../services/userProfile'
export default {
  components: {
    settingBankAccount,
    settingPinCode,
    forgetPinCode,
    settingNotification
    // otpSetting
  },
  data () {
    return {
      bankInfo: {},
      notifications_setting: {},
      activate_otb: null
    }
  },
  methods: {
    getWallaetDetails () {
      websiteServices.getWallaetDetails().then(res => {
        this.bankInfo = res.data.bank_info
        this.activate_otb = res.data.activate_otb
        this.notifications_setting = res.data.notifications_setting
      })
    }
  },
  created () {
    this.getWallaetDetails()
  }
}
</script>
